<template>
  <div>
    <component :is="computedComponentName" v-model="shouldShowModal" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center justify-between filter-title" :class="{ 'font-weight-bold': isFilterApplied }" v-on="on" v-bind="attrs">
          <div class="d-flex align-center flex-grow-1">
            <v-icon left> flag </v-icon>
            Location
          </div>
          <v-icon> expand_more </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none"> Location </v-card-title>

        <v-card-text class="pt-md-3" :class="{ 'black--text': isThemeLight }">
          <v-row>
            <!-- Audience Section -->
            <v-col v-for="kind in computedKinds" :key="kind" cols="12" :md="computedKinds.length === 1 ? 12 : 6">
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon :class="{ 'black--text': isThemeLight }" class="mr-1" left>
                    {{ kind === "audience" ? "people" : "volume_down" }}
                  </v-icon>

                  {{ computedKinds.length > 1 ? capitalizeString(kind) : "Location" }}
                </div>

                <v-tooltip bottom v-if="showTooltips">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="isThemeLight ? 'black' : null" v-bind="attrs" v-on="on"> info </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <v-autocomplete
                v-model="form[kind]"
                :items="searchItems"
                :label="onlyCountries ? 'Country' : onlyCities ? 'City' : 'City or Country'"
                dense
                eager
                outlined
                :append-icon="null"
                return-object
                hide-details
                item-text="name"
                item-value="id"
                full-width
                @change="submitForm(kind)"
              >
                <template v-slot:no-data>
                  <div class="text-center py-2">No results found.</div>
                </template>

                <!-- show user profile list for search results -->
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item ripple v-bind="attrs" v-on="on">
                    <v-list-item-action class="mr-2">
                      <v-img height="13" width="20" :src="proxyUrl(`https://flagcdn.com/w20/${item.country_code.toLowerCase()}.png`, true)"></v-img>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="first-letter-uppercase">
                        {{ item.type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <!-- Only show this option if we're using multiple inputs for this -->
              <div v-if="allowMultiple && appliedFiltersFor[kind].length" class="mt-3">
                <div v-for="item in appliedFiltersFor[kind]" :key="item.id">
                  <div class="d-flex justify-space-between align-center pb-1 pt-3">
                    <div class="d-flex align-center">
                      <v-btn small icon @click="removeFilterItem(item)">
                        <v-icon small> clear </v-icon>
                      </v-btn>

                      {{ item.data.inputs.name }}
                    </div>

                    <weight-selector v-if="kind === 'audience'" v-model="weightInput[item.timestamp]" @change="updateFilterItem(item)" />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import helper function
import dataHelper from "@/helpers/dataHelper"
import filterConverter from "@/helpers/filterConverter"

// Import the child components
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// Generator function for form data
const originalForm = () => ({
  audience: null,
  influencer: null
})

// Export the SFC
export default {
  // Name of the component
  name: "FilterLocation",

  // Accept incoming data from parent
  props: {
    moduleName: {
      type: String,
      required: true
    },

    showAudience: {
      type: Boolean,
      required: false,
      default: true
    },

    showTooltips: {
      type: Boolean,
      required: false,
      default: false
    },

    allowMultiple: {
      type: Boolean,
      required: false,
      default: true
    },

    useReplace: {
      type: Boolean,
      required: false,
      default: false
    },

    // Whether to show Audience/Influencer in filter label
    useLabel: {
      type: Boolean,
      required: false,
      default: true
    },

    // whether to only search countries in autocomplete
    onlyCountries: {
      type: Boolean,
      required: false,
      default: false
    },

    onlyCities: {
      type: Boolean,
      required: false,
      default: false
    },

    showStates: {
      type: Boolean,
      required: false,
      default: false
    },

    appliedFilters: {
      type: Array,
      required: false,
      default: () => []
    },

    isFilterApplied: {
      type: Boolean,
      required: true
    }
  },

  // Register the components
  components: {
    WeightSelector
  },

  // Define local data variables
  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    // The key would be the symbol for the applied filter in VueX state
    weightInput: {},

    locations: [],

    // for information tooltip
    tooltipTexts: {
      audience:
        "Identify influencers by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with influencers that have audience in ANY of the specified locations.",
      influencer:
        "Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations."
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Show the filter as a dialog for mobile devices
     *
     * @returns {String}
     */
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    /**
     * Get all the applied filter values
     *
     * @returns {Object}
     */
    appliedFiltersFor() {
      return {
        audience: this.appliedFilters.filter((item) => item.data.kind === "audience"),
        influencer: this.appliedFilters.filter((item) => item.data.kind === "influencer")
      }
    },

    /**
     * Get the filter types to be shown in the form
     *
     * @returns {Array}
     */
    computedKinds() {
      if (this.showAudience) return ["audience", "influencer"]
      else return ["influencer"]
    },

    /**
     * Get the list of items to be shown in the autocomplete
     *
     * @returns {Array}
     */
    searchItems() {
      return (
        this.locations
          // Only show the state items if we should
          .filter((item) => (this.showStates ? true : item.type !== "state"))
          // If we have to show only the cities
          .filter((item) => (this.onlyCities ? (item.type === "city" ? true : false) : true))
          // If we have to show only the countries
          .filter((item) => (this.onlyCountries ? (item.type === "country" ? true : false) : true))
          // Map out the data with a weight value
          .map((item) => ({ ...item, weight: 0.05 }))
      )
    }
  },

  // Vuelidate validation object
  validations: {
    form: {
      audience: {},
      influencer: {}
    }
  },

  // Define local method functions
  methods: {
    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     * @returns {void}
     */
    async submitForm(kind) {
      // Run validations
      await this.$v.form[kind].$touch()

      // If there's any error, stop further execution
      if (this.$v.form[kind].$anyError) return

      // Compute the API filter object
      const requestFilterData = {
        id: this.form[kind].model_id,
        weight: this.form[kind].weight,
        type: this.form[kind].type,
        row_id: this.form[kind].id
      }

      // Emit the submit event
      this.$emit(
        "submit",
        await filterConverter(
          kind === "audience" ? "audience_geo" : "geo",
          requestFilterData
        )
      )

      // reset the form input to allow more values
      this.form[kind] = null
      // finally hide the modal
      this.shouldShowModal = false
    },

    /**
     * Update the value of already existing filter
     * Most probably it'd just be the weight attribute
     *
     * @param {Object} item
     * @returns {void}
     */
    async updateFilterItem(item) {
      // Compute the API
      const requestFilterData = {
        ...item.data.inputs.requestData,
        weight: this.weightInput[item.timestamp]
      }

      this.$emit("update", {
        ...item,
        ...(await filterConverter(
          item.data.kind === "audience" ? "audience_geo" : "geo",
          requestFilterData
        ))
      })
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     * @returns {void}
     */
    removeFilterItem(item) {
      this.$emit("remove", item)
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  async created() {
    // Fetch and store interests in the store
    this.locations = await dataHelper.loadData("locations.json")
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Clear the locations array
    dataHelper.unloadData("locations.json")
  }
}
</script>
